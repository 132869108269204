import type { DimensionWithValue } from './analytics'
import {
  TABLE_ATTRIBUTES,
  TABLE_CELL_ATTRIBUTES,
  TABLE_ROW_ATTRIBUTES
} from '~/constants/bdese'

export interface TableCell {
  isHeader: boolean
  content: string | null
  dimensionsWithValue?: DimensionWithValue[]
  attributes?: { colSpan?: string; initialValue?: string }
}

export interface TableData {
  headerRows: TableCell[][]
  bodyRows: TableCell[][]
  totalRow?: TableCell[] | null
}

export enum JustifyContent {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export interface TableColumn {
  key: string | null
  value: string | null
  isSortable?: boolean
  justifyHeader?: JustifyContent
  countDistinct?: number
}

type TextNode = {
  type: 'text'
  text: string
}

type ParagraphNode = {
  type: 'paragraph'
  attrs: {
    textAlign: string
  }
  content?: TextNode[]
}

type TableCellNode = {
  type: 'tableCell' | 'tableHeader'
  attrs: {
    [key in (typeof TABLE_CELL_ATTRIBUTES)[keyof typeof TABLE_CELL_ATTRIBUTES]]?: string
  } & { colspan: number }
  content: ParagraphNode[]
}

type TableRowNode = {
  type: 'tableRow'
  attrs: {
    [key in (typeof TABLE_ROW_ATTRIBUTES)[keyof typeof TABLE_ROW_ATTRIBUTES]]?: string
  }
  content: TableCellNode[]
}

export type TableNode = {
  type: 'table'
  attrs: {
    [key in (typeof TABLE_ATTRIBUTES)[keyof typeof TABLE_ATTRIBUTES]]?: string
  }
  content: TableRowNode[]
}
